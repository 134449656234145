import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// import { setContext } from 'apollo-link-context'

//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
const Dashboard = () => import('@/views/backend/Dashboards/Dashboard')
const dash = () => import('@/views/backend/Dashboards/dash')
const Customer = () => import('@/views/backend/Dashboards/Customer')
const InvoiceStripe = () => import('@/views/backend/Dashboards/InvoiceStripe')
const InvoiceStripeAdd = () => import('@/views/backend/Dashboards/InvoiceStripeAdd')
const InvoiceStripeView = () => import('@/views/backend/Dashboards/InvoiceStripeView')
const InvoiceCheckout = () => import('@/views/backend/Dashboards/InvoiceCheckout')
const InvoiceCheckoutAdd = () => import('@/views/backend/Dashboards/InvoiceCheckoutAdd')
const InvoiceCheckoutView = () => import('@/views/backend/Dashboards/InvoiceCheckoutView')

const InvoiceCashFree = () => import('@/views/backend/Dashboards/InvoiceCashFree')
const InvoiceCashFreeAdd = () => import('@/views/backend/Dashboards/InvoiceCashFreeAdd')
const InvoiceCashFreeView = () => import('@/views/backend/Dashboards/InvoiceCashFreeView')

const Jobs = () => import('@/views/backend/Dashboards/Jobs')
const JobTimeline = () => import('@/views/backend/Dashboards/JobTimeline')
const ScheduleViewsList = () => import('@/views/backend/Dashboards/ScheduleViewsList')
const ScheduleView = () => import('@/views/backend/Dashboards/ScheduleView')

const PricingList = () => import('@/views/backend/Dashboards/PricingList')

// const Product = () => import('@/views/backend/Dashboards/Product')
const Orders = () => import('@/views/backend/Dashboards/Order')
// const ProductAdd = () => import('@/views/backend/Dashboards/ProductAdd')
const CustomerAdd = () => import('@/views/backend/Dashboards/CustomerAdd')
const CustomerEdit = () => import('@/views/backend/Dashboards/CustomerEdit')
const CustomerView = () => import('@/views/backend/Dashboards/CustomerView')
const OrderDetails = () => import('@/views/backend/Dashboards/OrderDetails')
const OrderNew = () => import('@/views/backend/Dashboards/OrderNew')
const OrderEdit = () => import('@/views/backend/Dashboards/OrderEdit')
const OrderUpload = () => import('@/views/backend/Dashboards/OrderUpload')
const CarAdd = () => import('@/views/backend/Dashboards/CarAdd')
const CarEdit = () => import('@/views/backend/Dashboards/CarEdit')
const CarView = () => import('@/views/backend/Dashboards/CarView')

const InvoiceCash = () => import('@/views/backend/Dashboards/InvoiceCash')
const InvoiceCashView = () => import('@/views/backend/Dashboards/InvoiceCashView')

const InvoiceCyberSource = () => import('@/views/backend/Dashboards/InvoiceCyberSource')
const InvoiceAddCyberSource = () => import('@/views/backend/Dashboards/InvoiceAddCyberSource')
const InvoiceViewCyberSource = () => import('@/views/backend/Dashboards/InvoiceViewCyberSource')

const InvoiceTabby = () => import('@/views/backend/Dashboards/InvoiceTabby')
const InvoiceTabbyAdd = () => import('@/views/backend/Dashboards/InvoiceTabbyAdd')
const InvoiceTabbyView = () => import('@/views/backend/Dashboards/InvoiceTabbyView')

const washSubscription = () => import('@/views/backend/Dashboards/WashSubscription')
const WashSubscriptionAdd = () => import('@/views/backend/Dashboards/WashSubscriptionAdd')
const WashSubscriptionView = () => import('@/views/backend/Dashboards/WashSubscriptionView')

const AddOnServices = () => import('@/views/backend/Dashboards/AddOnServices')
const AddOnServicesView = () => import('@/views/backend/Dashboards/AddOnServicesView')
const AddOnServicesAdd = () => import('@/views/backend/Dashboards/AddOnServicesAdd')

const UploadEstimate = () => import('@/views/backend/Dashboards/UploadEstimatesList')
const UploadEstimateEdit = () => import('@/views/backend/Dashboards/UploadEstimateEdit')
const UploadEstimateAdd = () => import('@/views/backend/Dashboards/UploadEstimateAdd')
const UploadEstimateView = () => import('@/views/backend/Dashboards/UploadEstimateView')

const Subscription = () => import('@/views/backend/Dashboards/Subscription')
const SubscriptionView = () => import('@/views/backend/Dashboards/SubscriptionView')

const Voucher = () => import('@/views/backend/Dashboards/Voucher')
const VoucherView = () => import('@/views/backend/Dashboards/VoucherView')

const ScheduleBlock = () => import('@/views/backend/Dashboards/ScheduleBlock')
const ScheduleBlockAdd = () => import('@/views/backend/Dashboards/ScheduleBlockAdd')
const ScheduleBlockView = () => import('@/views/backend/Dashboards/ScheduleBlockView')
const ScheduleBlockEdit = () => import('@/views/backend/Dashboards/ScheduleBlockEdit')
const InvoiceMoneyHash = () => import('@/views/backend/Dashboards/InvoiceMoneyHash')
const InvoiceMoneyHashAdd = () => import('@/views/backend/Dashboards/InvoiceMoneyHashAdd')
const InvoiceMoneyHashView = () => import('@/views/backend/Dashboards/InvoiceMoneyHashView')
const InvoicePaytabs = () => import('@/views/backend/Dashboards/InvoicePaytabs')
const InvoicePaytabsAdd = () => import('@/views/backend/Dashboards/InvoicePaytabsAdd')
const InvoicePaytabsView = () => import('@/views/backend/Dashboards/InvoicePaytabsView')
const InvoiceTotalPay = () => import('@/views/backend/Dashboards/InvoiceTotalPay')
const InvoiceTotalPayAdd = () => import('@/views/backend/Dashboards/InvoiceTotalPayAdd')
const InvoiceTotalPayView = () => import('@/views/backend/Dashboards/InvoiceTotalPayView')

const PackageViewsList = () => import('@/views/backend/Dashboards/PackageViewsList')
const PackageView = () => import('@/views/backend/Dashboards/PackageView')

const ServiceEnquiryList = () => import('@/views/backend/Dashboards/ServiceEnquiryList')
const ServiceEnquiryView = () => import('@/views/backend/Dashboards/ServiceEnquiryView')

const PackageTypeViews = () => import('@/views/backend/Dashboards/PackageTypeViews')
const PackageTypeView = () => import('@/views/backend/Dashboards/PackageTypeView')

const WorkshopOrder = () => import('@/views/backend/Dashboards/WorkshopOrder')
const WorkshopOrderView = () => import('@/views/backend/Dashboards/WorkshopOrderView')

const ServiceAreaViews = () => import('@/views/backend/Dashboards/ServiceAreaViews')
const ServiceAreaView = () => import('@/views/backend/Dashboards/ServiceAreaView')

//ui elements
// const UiAlerts = () => import('@/views/backend/Ui/UiAlerts')
// const UiAvatars = () => import('@/views/backend/Ui/UiAvatars')
// const UiBadges = () => import('@/views/backend/Ui/UiBadges')
// const UiBoxShadows = () => import('@/views/backend/Ui/UiBoxShadows')
// const UiBreadcrumbs = () => import('@/views/backend/Ui/UiBreadcrumbs')
// const UiButtonGroups = () => import('@/views/backend/Ui/UiButtonGroups')
// const UiButtons = () => import('@/views/backend/Ui/UiButtons')
// const UiCards = () => import('@/views/backend/Ui/UiCards')
// const UiCarousels = () => import('@/views/backend/Ui/UiCarousels')
// const UiColors = () => import('@/views/backend/Ui/UiColors')
// const UiEmbed = () => import('@/views/backend/Ui/UiEmbed')
// const UiGrids = () => import('@/views/backend/Ui/UiGrids')
// const UiHelperClasses = () => import('@/views/backend/Ui/UiHelperClasses')
// const UiImages = () => import('@/views/backend/Ui/UiImages')
// const UiListGroups = () => import('@/views/backend/Ui/UiListGroups')
// const UiMediaObjects = () => import('@/views/backend/Ui/UiMediaObjects')
// const UiModals = () => import('@/views/backend/Ui/UiModals')
// const UiNotifications = () => import('@/views/backend/Ui/UiNotifications')
// const UiPaginations = () => import('@/views/backend/Ui/UiPaginations')
// const UiPopOvers = () => import('@/views/backend/Ui/UiPopOvers')
// const UiProgressBars = () => import('@/views/backend/Ui/UiProgressBars')
// const UiTabs = () => import('@/views/backend/Ui/UiTabs')
// const UiTooltips = () => import('@/views/backend/Ui/UiTooltips')
// const UiTypography = () => import('@/views/backend/Ui/UiTypography')

//Form elements
// const Checkbox = () => import('@/views/backend/Forms/FormControls/Checkbox')
// const Elements = () => import('@/views/backend/Forms/FormControls/Elements')
// const Inputs = () => import('@/views/backend/Forms/FormControls/Inputs')
// const Radio = () => import('@/views/backend/Forms/FormControls/Radio')
// const Switch = () => import('@/views/backend/Forms/FormControls/Switch')
// const TextArea = () => import('@/views/backend/Forms/FormControls/TextArea')
// const Validations = () => import('@/views/backend/Forms/FormControls/Validations')
// const Datepicker = () => import('@/views/backend/Forms/Formwidget/Datepicker')
// const Fileupload = () => import('@/views/backend/Forms/Formwidget/Fileupload')
// const FormQuill = () => import('@/views/backend/Forms/Formwidget/FormQuill')
// const SelectComponents = () => import('@/views/backend/Forms/Formwidget/SelectComponents')
// const Simple = () => import('@/views/backend/Forms/FormWizard/Simple')
// const Validate = () => import('@/views/backend/Forms/FormWizard/Validate')
// const Vertical = () => import('@/views/backend/Forms/FormWizard/Vertical')

//table elements
// const BasicTable = () => import('@/views/backend/Table/BasicTable')
// const DataTable = () => import('@/views/backend/Table/DataTable')
// const EditTable = () => import('@/views/backend/Table/EditTable')

//icon elements
// const Dripicons = () => import('@/views/backend/Icons/Dripicons')
// const FontAwsome = () => import('@/views/backend/Icons/FontAwsome')
// const LineAwsome = () => import('@/views/backend/Icons/LineAwsome')
// const Remixicons = () => import('@/views/backend/Icons/Remixicons')

//chart elements
// const AmChart = () => import('@/views/backend/Chart/AmChart')
// const ApexChart = () => import('@/views/backend/Chart/ApexChart')
// const HighChart = () => import('@/views/backend/Chart/HighChart')

//map elements
// const GoogleMap = () => import('@/views/backend/Map/GoogleMap')
// const VectorMap = () => import('@/views/backend/Map/VectorMap')

//auth elements
const SignIn = () => import('@/views/backend/Auth/SignIn')
// const SignUp = () => import('@/views/backend/Auth/SignUp')
// const RecoverPassword = () => import('@/views/backend/Auth/RecoverPassword')
// const LockScreen = () => import('@/views/backend/Auth/LockScreen')
// const ConfirmMail = () => import('@/views/backend/Auth/ConfirmMail')

//pages elements
// const Pricing1 = () => import('@/views/backend/Pages/Pricing/Pricing1')
// const Pricing2 = () => import('@/views/backend/Pages/Pricing/Pricing2')
// const Pricing3 = () => import('@/views/backend/Pages/Pricing/Pricing3')
// const Pricing4 = () => import('@/views/backend/Pages/Pricing/Pricing4')
// const Timeline1 = () => import('@/views/backend/Pages/Timeline/Timeline1')
// const Timeline2 = () => import('@/views/backend/Pages/Timeline/Timeline2')
// const Timeline3 = () => import('@/views/backend/Pages/Timeline/Timeline3')
// const Timeline4 = () => import('@/views/backend/Pages/Timeline/Timeline4')
// const Error401 = () => import('@/views/backend/Pages/Error/Error401')
// const Error500 = () => import('@/views/backend/Pages/Error/Error500')
// const BlankPage = () => import('@/views/backend/Pages/BlankPage')

// const CommingSoon = () => import('@/views/backend/Pages/CommingSoon')
// const FAQ = () => import('@/views/backend/Pages/FAQ')
// const Invoice = () => import('@/views/backend/Pages/Invoice')
// const AddInvoice = () => import('@/views/backend/Pages/AddInvoice')
// const ViewInvoice = () => import('@/views/backend/Pages/ViewInvoice')
// const Maintainance = () => import('@/views/backend/Pages/Maintainance')
// const Subsribers = () => import('@/views/backend/Pages/Subsribers')
// const Comingsoon = () => import('@/views/backend/Pages/Comingsoon')

//app element
// const UserAccountSetting = () => import('@/views/backend/App/UserManagement/UserAccountSetting')
// const UserAdd = () => import('@/views/backend/App/UserManagement/UserAdd')
// const UserList = () => import('@/views/backend/App/UserManagement/UserList')
// const UserProfile = () => import('@/views/backend/App/UserManagement/UserProfile')
// const userPrivacySettings = () => import('@/views/backend/App/UserManagement/UserPrivacySetting')
// const UserProfileEdit = () => import('@/views/backend/App/UserManagement/UserProfileEdit')
// const Chat = () => import('@/views/backend/App/Chat')
// const Todo = () => import('@/views/backend/App/Todo')
// const Accountsetting = () => import('@/views/backend/Pages/Extrapages/AccountSettings')
// const privacypolicy = () => import('@/views/backend/Pages/Extrapages/PrivacyPolicy')
// const PrivacySettings = () => import('@/views/backend/Pages/Extrapages/PrivacySettings')
// const TermsOfUse = () => import('@/views/backend/Pages/Extrapages/TermsOfUse')
// const Icon = () => import('@/views/backend/Pages/Extrapages/Icon')

Vue.use(VueRouter)

const childRoute = () => [
  {
    path: 'order',
    name: 'order',
    meta: {
      name: 'order',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "All Orders", userCanFilter: true },
  },
  {
    path: 'unconfirmed-orders',
    name: 'unconfirmed-orders',
    meta: {
      name: 'unconfirmed-orders',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "Unconfirmed Orders", userCanFilter: false, filterIsConfirmed: false, filterIsNonWash: true }
  },
  {
    path: 'my-orders',
    name: 'my-orders',
    meta: {
      name: 'my-orders',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders", userCanFilter: false, filterIsAssignedToMe: true }
  },
  {
    path: 'my-orders-cars-in-workshop',
    name: 'my-orders-cars-in-workshop',
    meta: {
      name: 'my-orders-cars-in-workshop',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders - Cars in workshop", userCanFilter: false, filterIsAssignedToMe: true, filterIsNonWash: true }
  },
  {
    path: 'my-orders-inspected',
    name: 'my-orders-inspected',
    meta: {
      name: 'my-orders-inspected',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders - Inspection completed", userCanFilter: false, filterIsAssignedToMe: true, filterIsInspected: true }
  },
  {
    path: 'my-orders-not-quoted',
    name: 'my-orders-not-quoted',
    meta: {
      name: 'my-orders-not-quoted',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders - Not quoted", userCanFilter: false, filterIsAssignedToMe: true, filterIsQuoted: false, filterIsNonWash: true }
  },
  {
    path: 'my-orders-quoted',
    name: 'my-orders-quoted',
    meta: {
      name: 'my-orders-quoted',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders - Quoted (but not approved)", userCanFilter: false, filterIsAssignedToMe: true, filterIsQuoted: true }
  },
  {
    path: 'my-orders-approved',
    name: 'my-orders-approved',
    meta: {
      name: 'my-orders-approved',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: Orders,
    props: { titleText: "My Orders - Approved", userCanFilter: false, filterIsAssignedToMe: true, filterHasApprovedQuotation: true }
  },
  {
    path: 'pricing',
    name: 'pricing',
    meta: {
      name: 'pricing',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: PricingList
  },
  {
    path: 'jobs',
    name: 'jobs',
    meta: {
      name: 'jobs',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Jobs,
    props: { titleText: "All Jobs", userCanFilter: true }
  },
  {
    path: 'jobs-assigned',
    name: 'jobs-assigned',
    meta: {
      name: 'jobs-assigned',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Jobs,
    props: { titleText: "Jobs - Assigned but not yet accepted", userCanFilter: false, filterStatus: ['ASSIGNED'] }
  },
  {
    path: 'jobs-ongoing',
    name: 'jobs-ongoing',
    meta: {
      name: 'jobs-ongoing',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Jobs,
    props: { titleText: "Jobs - Ongoing", userCanFilter: false, filterStarted: true }
  },
  {
    path: 'jobs-paused',
    name: 'jobs-paused',
    meta: {
      name: 'jobs-paused',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Jobs,
    props: { titleText: "Jobs - Paused", userCanFilter: false, filterStatus: ['PAUSED'] }
  },
  {
    path: 'job-timeline',
    name: 'job-timeline',
    meta: {
      name: 'job-timeline',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: JobTimeline
  },
  {
    path: 'schedule-views',
    name: 'schedule-views',
    meta: { name: 'scheduleviews', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: ScheduleViewsList
  },
  {
    path: 'schedule-view/:id',
    name: 'schedule-views-view',
    meta: {
      name: 'scheduleview',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ScheduleView,
    props: true,
  },
  {
    path: 'invoice-cash',
    name: 'invoice-cash',
    meta: {
      name: 'invoice-cash',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCash
  },
  {
    path: 'invoice-cash/:id',
    name: 'invoice-cash-view',
    meta: {
      name: 'invoice-cash-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCashView,
    props: true,
  },
  {
    path: 'invoice-cybersource',
    name: 'invoice-cybersource',
    meta: {
      name: 'invoice-cybersource',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCyberSource
  },
  {
    path: 'invoice-cybersource/add',
    name: 'invoice-cybersource-add',
    meta: {
      name: 'invoice-cybersource-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceAddCyberSource
  },
  {
    path: 'invoice-cybersource/:id',
    name: 'invoice.viewcybersource',
    meta: {
      name: 'invoice-cybersource-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceViewCyberSource,
    props: true,
  },
  {
    path: 'dashboard',
    name: 'layout.dashboard',
    meta: {
      name: 'Dashboard',
      requiresAuth: true,
      roles: ['REGION_MANAGER']
    },
    component: Dashboard
  },
  {
    path: 'dash',
    name: 'layout.dash',
    meta: { name: 'dash', requiresAuth: true },
    component: dash
  },
  {
    path: 'customer',
    name: 'customer',
    meta: {
      name: 'customer',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Customer
  },
  // {
  //   path: 'search',
  //   name: 'layout.search',
  //   meta: {  name: 'search' },
  //   component: Search
  // },
  // {
  //   path: 'product',
  //   name: 'layout.product',
  //   meta: { name: 'product' },
  //   component: Product
  // },
  {
    path: 'invoice-checkout',
    name: 'invoice-checkout',
    meta: {
      name: 'invoice-checkout',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCheckout
  },
  {
    path: 'invoice-checkout/add/',
    name: 'invoice-checkout-add',
    component: InvoiceCheckoutAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  {
    path: 'invoice-checkout/:checkoutNumber',
    name: 'invoice-checkout-view',
    meta: {
      name: 'invoicecheckoutview',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCheckoutView,
    props: true,
  },
  {
    path: 'invoice-stripe',
    name: 'invoice-stripe',
    meta: {
      name: 'invoice-stripe',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceStripe
  },
  {
    path: 'invoice-stripe/add/',
    name: 'invoice-stripe-add',
    component: InvoiceStripeAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  {
    path: 'invoice-stripe/:stripeNumber',
    name: 'invoice-stripe-view',
    meta: {
      name: 'invoice-stripe-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceStripeView,
    props: true,
  },
  {
    path: 'invoice-tabby',
    name: 'invoice-tabby',
    meta: {
      name: 'invoice-tabby',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceTabby
  },
  {
    path: 'invoice-tabby/add/',
    name: 'invoice-tabby-add',
    component: InvoiceTabbyAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  {
    path: 'invoice-tabby/:tabbyNumber',
    name: 'invoice-tabby-view',
    meta: {
      name: 'invoice-tabby-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceTabbyView,
    props: true,
  },
  {
    path: 'invoice-cashfree',
    name: 'invoice-cashfree',
    meta: {
      name: 'invoice-cashfree',
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCashFree
  },
  {
    path: 'invoice-cashfree/add/',
    name: 'invoice-cashfree-add',
    component: InvoiceCashFreeAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  {
    path: 'invoice-cashfree/:cashfreeNumber',
    name: 'invoice-cashfree-view',
    meta: {
      name: 'invoice-cashfree-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceCashFreeView,
    props: true,
  },
  {
    path: 'customer/add',
    name: 'customer-add',
    meta: {
      name: 'customer-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: CustomerAdd
  },
  {
    path: 'customer/:number/edit',
    name: 'customer-edit',
    meta: {
      name: 'customer-edit',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: CustomerEdit,
    props: true,
  },
  {
    path: 'customer/:number',
    name: 'customer-view',
    meta: {
      name: 'customer-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: CustomerView,
    props: true,
  },
  {
    path: 'car/add/:number',
    component: CarAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },

  {
    path: '/car/edit/:userCarId/:number',
    component: CarEdit,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },

  {
    path: '/car/:userCarId/:number',
    component: CarView,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  // {
  //   path: 'productadd',
  //   name: 'product.productadd',
  //   meta: {
  //     name: 'productadd',
  //     roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
  //   },
  //   component: ProductAdd
  // },
  {
    path: 'order/add',
    name: 'order-add',
    meta: {
      name: 'order-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: OrderNew
  },
  {
    path: 'order/:orderId/add',
    name: 'order-duplicate',
    meta: {
      name: 'order-duplicate',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: OrderNew
  },
  {
    path: 'order/:orderId/edit',
    name: 'order-edit',
    meta: {
      name: 'order-edit',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: OrderEdit,
    props: true,
  },
  {
    path: 'order/:orderId/upload',
    name: 'order-upload',
    meta: {
      name: 'order-upload',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: OrderUpload,
    props: true,
  },
  {
    path: 'order/:orderId',
    name: 'order-view',
    meta: {
      name: 'order-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: OrderDetails,
    props: true,
  },
  {
    path: 'washsubscription',
    name: 'washsubscription',
    meta: {
      name: 'washsubscription',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: washSubscription
  },
  {
    path: 'washsubscription/add',
    name: 'washsubscription-add',
    meta: {
      name: 'washsubscription-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: WashSubscriptionAdd
  },
  {
    path: 'washsubscription/:washId',
    name: 'washsubscription-view',
    meta: {
      name: 'washsubscription-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: WashSubscriptionView,
    props: true,
  },
  {
    path: 'addonservice',
    name: 'addonservice',
    meta: {
      name: 'addonservice',
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP'],
      requiresAuth: true,
    },
    component: AddOnServices,
  },
  {
    path: 'addonservice/add',
    name: 'addonservice-add',
    meta: {
      name: 'addonservice-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: AddOnServicesAdd
  },
  {
    path: 'addonservice/:addonserviceId',
    name: 'addonservice-view',
    meta: {
      name: 'addonservice-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: AddOnServicesView,
    props: true,
  },
  {
    path: 'upload-estimate',
    name: 'upload-estimate',
    meta: {
      name: 'upload-estimate',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: UploadEstimate
  },
  {
    path: 'upload-estimate/:uploadestimateId/edit',
    name: 'upload-estimate-edit',
    meta: {
      name: 'upload-estimate-edit',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: UploadEstimateEdit,
    props: true,
  },
  {
    path: 'upload-estimate/add',
    name: 'upload-estimate-add',
    meta: {
      name: 'upload-estimate-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: UploadEstimateAdd
  },
  {
    path: 'upload-estimate/:uploadestimateId',
    name: 'upload-estimate-view',
    meta: {
      name: 'uploadestimateview',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: UploadEstimateView,
    props: true,
  },
  {
    path: 'subscription',
    name: 'subscription',
    meta: {
      name: 'subscription',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Subscription
  },
  {
    path: 'subscription/:id',
    name: 'subscription-view',
    meta: {
      name: 'subscription-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: SubscriptionView,
    props: true,
  },
  {
    path: 'voucher',
    name: 'voucher',
    meta: {
      name: 'voucher',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: Voucher
  },
  {
    path: 'voucher/:id',
    name: 'voucher-view',
    meta: {
      name: 'voucher-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: VoucherView,
    props: true,
  },
  {
    path: 'schedule-block',
    name: 'schedule-block',
    meta: {
      name: 'schedule-block',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ScheduleBlock
  },
  {
    path: 'schedule-block/:id',
    name: 'schedule-block-view',
    meta: {
      name: 'schedule-block-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ScheduleBlockView,
    props: true,
  },
  {
    path: 'schedule-block/add',
    name: 'schedule-block-add',
    meta: {
      name: 'schedule-block-add',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ScheduleBlockAdd,
    props: true,
  },
  {
    path: 'schedule-block/:id/edit',
    name: 'schedule-block-edit',
    meta: {
      name: 'schedule-block-edit',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ScheduleBlockEdit,
    props: true,
  },
  {
    path: 'invoice-moneyhash',
    name: 'invoice-moneyhash',
    meta: {
      name: 'invoice-moneyhash',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceMoneyHash
  },
  {
    path: 'invoice-moneyhash/add/',
    name: 'invoice-moneyhash-add',
    component: InvoiceMoneyHashAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    props: true,
  },
  {
    path: 'invoice-moneyhash/:number',
    name: 'invoice-moneyhash-view',
    meta: {
      name: 'invoice-moneyhash-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: InvoiceMoneyHashView,
    props: true,
  },
  {
    path: 'invoice-paytabs',
    name: 'invoice-paytabs',
    meta: {
      name: 'invoice-paytabs',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: InvoicePaytabs
  },
  {
    path: 'invoice-paytabs/add/',
    name: 'invoice-paytabs-add',
    component: InvoicePaytabsAdd,
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    props: true,
  },
  {
    path: 'invoice-paytabs/:number',
    name: 'invoice-paytabs-view',
    meta: {
      name: 'invoice-paytabs-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: InvoicePaytabsView,
    props: true,
  },
  {
    path: 'invoice-totalpay',
    name: 'invoice-totalpay',
    meta: {
      name: 'invoice-totalpay',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: InvoiceTotalPay
  },
  {
    path: 'invoice-totalpay/add/',
    name: 'invoice-totalpay-add',
    meta: {
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: InvoiceTotalPayAdd,
    props: true,
  },
  {
    path: 'invoice-totalpay/:number',
    name: 'invoice-totalpay-view',
    meta: {
      name: 'invoice-totalpay-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD']
    },
    component: InvoiceTotalPayView,
    props: true,
  },
  {
    path: 'package-views',
    name: 'package-views',
    meta: { name: 'packageviews', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: PackageViewsList
  },
  {
    path: 'package-view/:id',
    name: 'package-views-view',
    meta: {
      name: 'packageview',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: PackageView,
    props: true,
  },
  {
    path: 'service-enquiry',
    name: 'service-enquiry',
    meta: { name: 'serviceenquiries', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: ServiceEnquiryList
  },
  {
    path: 'service-enquiry/:id',
    name: 'service-enquiry-view',
    meta: {
      name: 'serviceenquiry',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ServiceEnquiryView,
    props: true,
  },
  {
    path: 'package-type-views',
    name: 'package-type-views',
    meta: { name: 'package-type-views', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: PackageTypeViews,
  },
  {
    path: 'package-type-views/:id',
    name: 'package-type-views-view',
    meta: {
      name: 'package-type-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: PackageTypeView,
    props: true,
  },
  {
    path: 'service-area-views',
    name: 'service-area-views',
    meta: { name: 'service-area-views', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: ServiceAreaViews,
  },
  {
    path: 'service-area-view/:id',
    name: 'service-area-views-view',
    meta: {
      name: 'service-area-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: ServiceAreaView,
    props: true,
  },
  {
    path: 'workshop-order',
    name: 'workshop-order',
    meta: { name: 'workshop-order', requiresAuth: true,
    roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']},
    component: WorkshopOrder,
  },
  {
    path: 'workshop-order/:id',
    name: 'workshop-order-view',
    meta: {
      name: 'workshop-order-view',
      requiresAuth: true,
      roles: ['REGION_MANAGER', 'OPERATIONS_LEAD', 'VALET', 'WASHER', 'MECHANIC', 'WORKSHOP']
    },
    component: WorkshopOrderView,
    props: true,
  },
  // {
  //   path: 'ui-avatars',
  //   name: 'Ui.avatars',
  //   meta: { name: 'Ui Avatars' },
  //   component: UiAvatars
  // },
  // {
  //   path: 'ui-alert',
  //   name: 'Ui.alerts',
  //   meta: { name: 'Ui Alert' },
  //   component: UiAlerts
  // },
  // {
  //   path: 'ui-badges',
  //   name: 'Ui.badges',
  //   meta: { name: 'Ui Badges' },
  //   component: UiBadges

  // },
  // {
  //   path: 'ui-boxshadows',
  //   name: 'Ui.boxshadows',
  //   meta: { name: 'Ui Box Shadows' },
  //   component: UiBoxShadows
  // },
  // {
  //   path: 'ui-breadcrumbs',
  //   name: 'Ui.breadcrumbs',
  //   meta: { name: 'Ui Breadcrumbs' },
  //   component: UiBreadcrumbs
  // },
  // {
  //   path: 'ui-button-groups',
  //   name: 'Ui.button-groups',
  //   meta: { name: 'Ui Button Groups' },
  //   component: UiButtonGroups
  // },
  // {
  //   path: 'ui-buttons',
  //   name: 'Ui.buttons',
  //   meta: { name: 'Ui Buttons' },
  //   component: UiButtons
  // },
  // {
  //   path: 'ui-cards',
  //   name: 'Ui.cards',
  //   meta: { name: 'Ui Cards' },
  //   component: UiCards
  // },
  // {
  //   path: 'ui-carousels',
  //   name: 'Ui.carousels',
  //   meta: { name: 'Ui Carousels' },
  //   component: UiCarousels
  // },
  // {
  //   path: 'ui-colors',
  //   name: 'Ui.colors',
  //   meta: { name: 'Ui Colors' },
  //   component: UiColors
  // },
  // {
  //   path: 'ui-embed-videos',
  //   name: 'Ui.Embed-videos',
  //   meta: { name: 'Ui Embed' },
  //   component: UiEmbed
  // },
  // {
  //   path: 'ui-grids',
  //   name: 'Ui.grids',
  //   meta: { name: 'Ui Grids' },
  //   component: UiGrids
  // },
  // {
  //   path: 'ui-helper-classes',
  //   name: 'Ui.helper-classes',
  //   meta: { name: 'Ui Helper Classes' },
  //   component: UiHelperClasses
  // },
  // {
  //   path: 'ui-images',
  //   name: 'Ui.images',
  //   meta: { name: 'Ui Images' },
  //   component: UiImages
  // },
  // {
  //   path: 'ui-list-groups',
  //   name: 'Ui.list-groups',
  //   meta: { name: 'Ui List Groups' },
  //   component: UiListGroups
  // },
  // {
  //   path: 'ui-media-objects',
  //   name: 'Ui.media-objects',
  //   meta: { name: 'Ui Media Objects' },
  //   component: UiMediaObjects
  // },
  // {
  //   path: 'ui-modals',
  //   name: 'Ui.modals',
  //   meta: { name: 'Ui Modals' },
  //   component: UiModals
  // },
  // {
  //   path: 'ui-notifications',
  //   name: 'Ui.notifications',
  //   meta: { name: 'Ui Notifications' },
  //   component: UiNotifications
  // },
  // {
  //   path: 'ui-paginations',
  //   name: 'Ui.paginations',
  //   meta: { name: 'Ui Pagination' },
  //   component: UiPaginations
  // },
  // {
  //   path: 'ui-popovers',
  //   name: 'Ui.popovers',
  //   meta: { name: 'Ui popovers' },
  //   component: UiPopOvers
  // },
  // {
  //   path: 'ui-progressbars',
  //   name: 'Ui.progressbars',
  //   meta: { name: 'Ui Progressbar' },
  //   component: UiProgressBars
  // },
  // {
  //   path: 'ui-tabs',
  //   name: 'Ui.tabs',
  //   meta: { name: 'Ui Tabs' },
  //   component: UiTabs
  // },
  // {
  //   path: 'ui-tooltips',
  //   name: 'Ui.tooltips',
  //   meta: { name: 'Ui Tooltips' },
  //   component: UiTooltips
  // },
  // {
  //   path: 'ui-typography',
  //   name: 'Ui.typographys',
  //   meta: { name: 'Ui Typography' },
  //   component: UiTypography
  // },
  // {
  //   path: 'form-checkbox',
  //   name: 'controls.form-checkbox',
  //   meta: { name: 'Form Checkbox' },
  //   component: Checkbox
  // },
  // {
  //   path: 'form-layouts',
  //   name: 'controls.form-layout',
  //   meta: { name: 'Form Layouts' },
  //   component: Elements
  // },
  // {
  //   path: 'form-input',
  //   name: 'controls.form-input',
  //   meta: { name: 'Form Input' },
  //   component: Inputs
  // },
  // {
  //   path: 'form-radio',
  //   name: 'controls.form-radio',
  //   meta: { name: 'Form Radio' },
  //   component: Radio
  // },
  // {
  //   path: 'form-switch',
  //   name: 'controls.form-switch',
  //   meta: { name: 'Form Switch' },
  //   component: Switch
  // },
  // {
  //   path: 'form-textarea',
  //   name: 'controls.form-textarea',
  //   meta: { name: 'Form TextArea' },
  //   component: TextArea
  // },
  // {
  //   path: 'form-validation',
  //   name: 'controls.form-validation',
  //   meta: { name: 'Form Validation' },
  //   component: Validations
  // },
  // {
  //   path: 'form-datepicker',
  //   name: 'widget.form-datepicker',
  //   meta: { name: 'Form Datepicker' },
  //   component: Datepicker
  // },
  // {
  //   path: 'form-file-uploader',
  //   name: 'widget.form-file-uploader',
  //   meta: { name: 'Form Fileupload' },
  //   component: Fileupload
  // },
  // {
  //   path: 'form-quill',
  //   name: 'widget.form-quill',
  //   meta: { name: 'Form quill' },
  //   component: FormQuill
  // },
  // {
  //   path: 'form-select',
  //   name: 'widget.form-select',
  //   meta: { name: 'Form Select2' },
  //   component: SelectComponents
  // },
  // {
  //   path: 'form-wizard',
  //   name: 'wizard.form-wizard',
  //   meta: { name: 'Form Wizard' },
  //   component: Simple
  // }, {
  //   path: 'form-wizard-validate',
  //   name: 'wizard.form-wizard-validate',
  //   meta: { name: 'Form Wizard Validate' },
  //   component: Validate
  // }, {
  //   path: 'form-wizard-vertical',
  //   name: 'wizard.form-wizard-vertical',
  //   meta: { name: 'Form Wizard Vertical' },
  //   component: Vertical
  // },
  // {
  //   path: 'basic-table',
  //   name: 'table.basic-table',
  //   meta: { name: 'Basic Table ' },
  //   component: BasicTable
  // },
  // {
  //   path: 'data-table',
  //   name: 'table.data-table',
  //   meta: { name: 'Data Table ' },
  //   component: DataTable
  // },
  // {
  //   path: 'edit-table',
  //   name: 'table.edit-table',
  //   meta: { name: 'Edit Table ' },
  //   component: EditTable
  // },
  // {
  //   path: 'icon-dripicons',
  //   name: 'icon.dripicon',
  //   meta: { name: 'Dripicons ' },
  //   component: Dripicons
  // }, {
  //   path: 'icon-fontawsome',
  //   name: 'icon.fontawsome',
  //   meta: { name: 'FontAwsome ' },
  //   component: FontAwsome
  // }, {
  //   path: 'icon-lineawsome',
  //   name: 'icon.lineawsome',
  //   meta: { name: 'LineAwsome ' },
  //   component: LineAwsome
  // }, {
  //   path: 'icon-remixicon',
  //   name: 'icon.remixicon',
  //   meta: { name: 'Remixicon ' },
  //   component: Remixicons
  // },
  // {
  //   path: 'am-chart',
  //   name: 'chart.amchart',
  //   meta: { name: 'Amchart' },
  //   component: AmChart
  // },
  // {
  //   path: 'apex-chart',
  //   name: 'chart.apexchart',
  //   meta: { name: 'Apexchart' },
  //   component: ApexChart
  // },
  // {
  //   path: 'high-chart',
  //   name: 'chart.highchart',
  //   meta: { name: 'Highchart' },
  //   component: HighChart
  // },
  // {
  //   path: 'google-map',
  //   name: 'map.google',
  //   meta: { name: 'Google Map' },
  //   component: GoogleMap
  // },
  // {
  //   path: 'vector-map',
  //   name: 'map.vector',
  //   meta: { name: 'Vector Map' },
  //   component: VectorMap
  // },
  // {
  //   path: 'user-add',
  //   name: 'app.user-add',
  //   meta: { name: 'user-add' },
  //   component: UserAdd
  // },
  // {
  //   path: 'user-list',
  //   name: 'app.user-list',
  //   meta: { name: 'User Add' },
  //   component: UserList
  // },
  // {
  //   path: 'user-profile',
  //   name: 'app.user-profile',
  //   meta: { name: 'User Profile' },
  //   component: UserProfile
  // },
  // {
  //   path: 'user-privacy-settings',
  //   name: 'app.user-privacy-setting',
  //   meta: { name: 'user-privacy-setting' },
  //   component: userPrivacySettings
  // },
  // {
  //   path: 'User-profile-edit',
  //   name: 'app.user-profile-edit',
  //   meta: { name: 'user-profile-edit' },
  //   component: UserProfileEdit
  // },
  // {
  //   path: 'User-account-setting',
  //   name: 'app.user-Account-setting',
  //   meta: { name: 'user account setting' },
  //   component: UserAccountSetting
  // },
  // {
  //   path: 'chat',
  //   name: 'app.chat',
  //   meta: { name: 'Chat' },
  //   component: Chat
  // },
  // {
  //   path: 'todo',
  //   name: 'app.todo',
  //   meta: { name: 'Todo' },
  //   component: Todo
  // },
  // {
  //   path: 'privacy-settings',
  //   name: 'app.Privacysettings',
  //   meta: { name: 'Privacysettings' },
  //   component: PrivacySettings
  // },
  // {
  //   path: 'terms-of-use',
  //   name: 'app.Termsofuse',
  //   meta: { name: 'Termsofuse' },
  //   component: TermsOfUse
  // },
  // {
  //   path: 'privacy-policy',
  //   name: 'app.privacypolicy',
  //   meta: { name: 'privacypolicy' },
  //   component: privacypolicy
  // },
  // {
  //   path: 'account-setting',
  //   name: 'app.Accountsetting',
  //   meta: { name: 'Accountsetting' },
  //   component: Accountsetting
  // },
]
const authchildRoute = () => [
  {
    path: 'sign-in',
    name: 'auth.login',
    meta: { name: 'SignIn' },
    component: SignIn
  },
  // {
  //   path: 'sign-up',
  //   name: 'auth.register',
  //   meta: { name: 'SignUp' },
  //   component: SignUp
  // },
  // {
  //   path: 'recover-password',
  //   name: 'auth.recover-password',
  //   meta: { name: 'Recover Password' },
  //   component: RecoverPassword
  // },
  // {
  //   path: 'lock-screen',
  //   name: 'auth.lock-screen',
  //   meta: { name: 'Lock Screen' },
  //   component: LockScreen
  // },
  // {
  //   path: 'confirm-mail',
  //   name: 'auth.confirm-mail',
  //   meta: { name: 'Confirm Mail' },
  //   component: ConfirmMail
  // },
]
// const pageschildRoute = () => [
//   {
//     path: 'error-401',
//     name: 'error.401',
//     meta: { name: 'Error 401' },
//     component: Error401
//   },
//   {
//     path: 'error-500',
//     name: 'error.500',
//     meta: { name: 'Error 500' },
//     component: Error500
//   },
//   {
//     path: 'pages-maintainance',
//     name: 'pages.maintenance',
//     meta: { name: 'Maintaiance' },
//     component: Maintainance
//   },
//   {
//     path: 'pages-comingsoon',
//     name: 'pages.comingsoon',
//     meta: { name: 'Coming soon' },
//     component: Comingsoon
//   },
// ]
// const extrapageschildRoute = () => [
//   {
//     path: 'timeline-1',
//     name: 'time.line',
//     meta: { name: 'TimeLine-1' },
//     component: Timeline1
//   },
//   {
//     path: 'timeline-2',
//     name: 'time.line1',
//     meta: { name: 'TimeLine-2' },
//     component: Timeline2
//   },
//   {
//     path: 'timeline-3',
//     name: 'time.line2',
//     meta: { name: 'TimeLine-3' },
//     component: Timeline3
//   },
//   {
//     path: 'timeline-4',
//     name: 'time.line3',
//     meta: { name: 'TimeLine-4' },
//     component: Timeline4
//   },
//   {
//     path: 'pricing-1',
//     name: 'price.pay',
//     meta: { name: 'Pricing-1' },
//     component: Pricing1
//   },
//   {
//     path: 'pricing-2',
//     name: 'price.pay1',
//     meta: { name: 'Pricing-2' },
//     component: Pricing2
//   },
//   {
//     path: 'pricing-3',
//     name: 'price.pay2',
//     meta: { name: 'Pricing-3' },
//     component: Pricing3
//   },
//   {
//     path: 'pricing-4',
//     name: 'price.pay3',
//     meta: { name: 'Pricing-4' },
//     component: Pricing4
//   },
//   {
//     path: 'pages-invoice',
//     name: 'pages.invoices',
//     meta: { name: 'Pages Invoice' },
//     component: Invoice
//   },
//   {
//     path: 'pages-addinvoice',
//     name: 'pages.addinvoices',
//     meta: { name: 'Pages Add Invoice' },
//     component: AddInvoice
//   },
//   {
//     path: 'pages-viewinvoice',
//     name: 'pages.viewinvoices',
//     meta: { name: 'Pages View Invoice' },
//     component: ViewInvoice
//   },
//   {
//     path: 'pages-subscriber',
//     name: 'pages.subscribers',
//     meta: { name: 'Pages Subscribers' },
//     component: Subsribers
//   },
//   {
//     path: 'pages-faq',
//     name: 'pages.faq',
//     meta: { name: 'Pages FAQ' },
//     component: FAQ
//   },
//   {
//     path: 'pages-blank-page',
//     name: 'pages.blank-page',
//     meta: { name: 'Pages Blank Page ' },
//     component: BlankPage
//   },
//   {
//     path: 'pages-icon',
//     name: 'pages.icon',
//     meta: { name: 'Pages Icon' },
//     component: Icon
//   },
// ]

const routes = [
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  // {
  //   path: '/pages',
  //   name: 'pages',
  //   component: BlankLayout,
  //   children: pageschildRoute()
  // },
  // {
  //   path: '/extra-pages',
  //   name: 'extra-pages',
  //   component: Layout1,
  //   children: extrapageschildRoute()
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})



const token = localStorage.getItem("apollo-token");
router.headers = {
  'accept': 'application/json',
  'content-type': 'application/json',

  'X-Client': process.env.VUE_APP_API_CLIENT,
  "authorization": token ? 'JWT ' + token: ""
}

const resize = () => {
  var ww = document.body.clientWidth;
  var body = document.body;
  if(document.body.classList.contains('sidebar-main')){
    if (ww < 1300) {
      // var body = document.body;
      body.classList.remove("sidebar-main");
    } else {
      //  var body = document.body;
      body.classList.add("sidebar-main");
    }
  }

}
router.beforeEach((to, from, next) => {
  //for hiding sidebar by default
  // const userDetails = JSON.parse(localStorage.getItem('user-details'))
  resize();
  let isUserLoggedIn
  if (localStorage.getItem("apollo-token") !== null) {
    isUserLoggedIn = true;
  }
  if (to.name === '') {
    next({
      path: '/dashboard',
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('logOut')
      // router.push({ path: 'Onboarding' });
      next({
        path: '/auth/sign-in',
        query: { redirect: to.fullPath }
      })
    } else {
      // if (to.meta?.roles?.includes(userDetails.jobProfile.type)) {
      //   next()
      // }
      // else {
        next()
      // }
    }
  }
  else {
    next()
  }
})

export default router
